<template>
  <div id="reportCenter" v-loading="loading">
    <div class="tip-top">每日血糖</div>
    <div class="back" style="position: absolute; top: 1rem; right: 13rem" @click="toMyPatientList()" v-show="isPatient">
      返回我的患者
    </div>
    <!-- <div
      @click="saveImage('html2canvas', '血糖报告')"
      style="
        width: 3rem;
        height: 3rem;
        position: absolute;
        right: 1rem;
        top: 0.2rem;
      "
    >
      <img src="../../static/pic/share.png" style="width: 3rem; height: 3rem" />
    </div> -->
    <!-- 日期选择 -->
    <div>
      <van-nav-bar
        title="标题"
        left-text="前一天"
        left-arrow
        @click-left="onClickLeft"
        @click-right="nowDate !== selectedDate ? onClickRight() : null"
      >
        <template #right>
          <div v-if="nowDate !== selectedDate" style="color: #1989fa">后一天</div>
          <van-icon v-if="nowDate !== selectedDate" name="arrow" />
        </template>
        <template #title>
          <div
            @click="showDialog(showStart)"
            style="background: #7accff; padding: 2vw; border-radius: 20px; color: #fff"
          >
            {{ selectedDate }}
            <van-icon name="notes-o" color="#fff" />
          </div>
        </template>
      </van-nav-bar>

      <van-calendar
        v-model="showStart"
        color="#7accff"
        position="top"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmStart"
        @close="cancelStart"
      />
    </div>

    <div :class="SugerData.length == 0 ? 'none' : 'show'" id="html2canvas" ref="html2canvas">
      <!-- 每日血糖概览 -->
      <div
        class="box-shadow"
        style="
          width: 94vw;
          margin: 0.5rem auto 0.8rem auto;
          height: 10rem;
          padding-top: 1rem;
          border-radius: 8px;
          background: #fff;
        "
      >
        <div style="font-size: 1.2rem; font-weight: 600; text-align: left; margin-left: 1rem">每日血糖概览</div>
        <el-row style="height: 10rem">
          <el-col :span="12">
            <div
              style="
                border-radius: 8px;
                background: #e8f6ff;
                margin-top: 0.5rem;
                width: 45vw;
                margin-left: 1vw;
                height: 3.5rem;
              "
            >
              <div style="color: #7a7a7a; font-size: 0.9rem; height: 3.5rem; line-height: 3.5rem">
                平均葡萄糖水平
                <span style="font-size: 1.1rem; color: #000; font-weight: bold">{{ dayAverageSuger }}</span>
                <span style="color: #000; font-size: 0.8rem; font-weight: bold">mmol/L</span>
              </div>
            </div>
            <div
              style="
                border-radius: 8px;
                background: #e8f6ff;
                margin-top: 0.5rem;
                width: 45vw;
                margin-left: 1vw;
                height: 3.5rem;
              "
            >
              <div style="color: #7a7a7a; font-size: 0.9rem; height: 3.5rem; line-height: 3.5rem">
                最大血糖波动幅度
                <span style="font-size: 1.1rem; color: #000; font-weight: bold">{{ dayMaxRange }}</span>
                <span style="color: #000; font-size: 0.8rem; font-weight: bold">mmol/L</span>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              style="
                border-radius: 8px;
                background: #e8f6ff;
                margin-top: 0.5rem;
                width: 45vw;
                margin-left: 1vw;
                height: 3.5rem;
              "
            >
              <div style="color: #7a7a7a; font-size: 0.9rem; height: 3.5rem; line-height: 3.5rem">
                高血糖数
                <span style="font-size: 1.1rem; color: #000; font-weight: bold">{{ dayMaxSugar }}</span>
                <span style="color: #000; font-size: 0.8rem; font-weight: bold">次</span>
              </div>
            </div>
            <div
              style="
                border-radius: 8px;
                background: #e8f6ff;
                margin-top: 0.5rem;
                width: 45vw;
                margin-left: 1vw;
                height: 3.5rem;
              "
            >
              <div style="color: #7a7a7a; font-size: 0.9rem; height: 3.5rem; line-height: 3.5rem">
                低血糖数
                <span style="font-size: 1.1rem; color: #000; font-weight: bold">{{ dayMinSugar }}</span>
                <span style="color: #000; font-size: 0.8rem; font-weight: bold">次</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 每日闭环概览 -->
      <!-- <div
        class="box-shadow"
        style="
          width: 94vw;
          margin: 0.5rem auto 0.8rem auto;
          height: 19rem;
          padding-top: 1rem;
          border-radius: 8px;
          background: #fff;
        "
      >
        <div
          style="
            font-size: 1.2rem;
            font-weight: 600;
            text-align: left;
            margin-left: 1rem;
          "
        >
          每日闭环概览
        </div>
        <el-row style="height: 10rem">
          <el-col :span="12">
            <div
              style="
                border-radius: 8px;
                background: #e8f6ff;
                margin-top: 0.5rem;
                width: 45vw;
                margin-left: 1vw;
                height: 3.5rem;
              "
            >
              <div
                style="
                  color: #7a7a7a;
                  font-size: 1rem;
                  height: 3.5rem;
                  line-height: 3.5rem;
                "
              >
                大剂量胰岛素
                <span
                  style="font-size: 1.2rem; color: #000; font-weight: bold"
                  >{{ "-" }}</span
                ><span style="color: #000; font-size: 0.8rem; font-weight: bold"
                  >U</span
                >
              </div>
            </div>
            <div
              style="
                border-radius: 8px;
                background: #e8f6ff;
                margin-top: 0.5rem;
                width: 45vw;
                margin-left: 1vw;
                height: 3.5rem;
              "
            >
              <div
                style="
                  color: #7a7a7a;
                  font-size: 1rem;
                  height: 3.5rem;
                  line-height: 3.5rem;
                "
              >
                基础率胰岛素
                <span
                  style="font-size: 1.2rem; color: #000; font-weight: bold"
                  >{{ "-" }}</span
                ><span style="color: #000; font-size: 0.8rem; font-weight: bold"
                  >U</span
                >
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              style="
                border-radius: 8px;
                background: #e8f6ff;
                margin-top: 0.5rem;
                width: 45vw;
                margin-left: 1vw;
                height: 3.5rem;
              "
            >
              <div
                style="
                  color: #7a7a7a;
                  font-size: 1rem;
                  height: 3.5rem;
                  line-height: 3.5rem;
                "
              >
                实际临时基础率胰岛素
                <span style="font-size: 1.2rem; color: #000; font-weight: bold"
                  >-</span
                ><span style="color: #000; font-size: 0.8rem; font-weight: bold"
                  >U</span
                >
              </div>
            </div>
            <div
              style="
                border-radius: 8px;
                background: #e8f6ff;
                margin-top: 0.5rem;
                width: 45vw;
                margin-left: 1vw;
                height: 3.5rem;
              "
            >
              <div
                style="
                  color: #7a7a7a;
                  font-size: 1rem;
                  height: 3.5rem;
                  line-height: 3.5rem;
                "
              >
                其余临时基础率胰岛素
                <span style="font-size: 1.2rem; color: #000; font-weight: bold"
                  >-</span
                ><span style="color: #000; font-size: 0.8rem; font-weight: bold"
                  >U</span
                >
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              style="
                border-radius: 8px;
                background: #e8f6ff;
                margin-top: 0.5rem;
                width: 45vw;
                margin-left: 1vw;
                height: 3.5rem;
              "
            >
              <div
                style="
                  color: #7a7a7a;
                  font-size: 1rem;
                  height: 3.5rem;
                  line-height: 3.5rem;
                "
              >
                基础率胰岛素合计
                <span style="font-size: 1.2rem; color: #000; font-weight: bold"
                  >-</span
                ><span style="color: #000; font-size: 0.8rem; font-weight: bold"
                  >U</span
                >
              </div>
            </div>
            <div
              style="
                border-radius: 8px;
                background: #e8f6ff;
                margin-top: 0.5rem;
                width: 45vw;
                margin-left: 1vw;
                height: 3.5rem;
              "
            >
              <div
                style="
                  color: #7a7a7a;
                  font-size: 1rem;
                  height: 3.5rem;
                  line-height: 3.5rem;
                "
              >
                每日胰岛素合计
                <span style="font-size: 1.2rem; color: #000; font-weight: bold"
                  >-</span
                ><span style="color: #000; font-size: 0.8rem; font-weight: bold"
                  >U</span
                >
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              style="
                border-radius: 8px;
                background: #e8f6ff;
                margin-top: 0.5rem;
                width: 45vw;
                margin-left: 1vw;
                height: 3.5rem;
              "
            >
              <div
                style="
                  color: #7a7a7a;
                  font-size: 1rem;
                  height: 3.5rem;
                  line-height: 3.5rem;
                "
              >
                碳水化合物总摄入量
                <span style="font-size: 1.2rem; color: #000; font-weight: bold"
                  >-</span
                ><span style="color: #000; font-size: 0.8rem; font-weight: bold"
                  >g</span
                >
              </div>
            </div>
            <div
              style="
                border-radius: 8px;
                background: #e8f6ff;
                margin-top: 0.5rem;
                width: 45vw;
                margin-left: 1vw;
                height: 3.5rem;
              "
            >
              <div
                style="
                  color: #7a7a7a;
                  font-size: 1rem;
                  height: 3.5rem;
                  line-height: 3.5rem;
                "
              >
                蛋白质总摄入量
                <span style="font-size: 1.2rem; color: #000; font-weight: bold"
                  >-</span
                ><span style="color: #000; font-size: 0.8rem; font-weight: bold"
                  >g</span
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div> -->

      <!-- 每日血糖曲线 -->
      <Report ref="nsPages" :selectedDate="selectedDate" />
      <!-- <todaySugar :selectedDate="selectedDate" /> -->

      <!-- 周期血糖 -->
      <div class="tip">周期血糖</div>
      <!-- 导出报告 -->
      <div
        class="box-shadow"
        style="width: 94vw; margin: 0.5rem auto 0.8rem auto; height: 4.5rem; border-radius: 8px; background: #fff"
      >
        <el-row style="text-align: left; height: 4rem">
          <el-col :span="18">
            <div style="padding-left: 1rem; font-size: 0.8rem; color: #7a7a7a; height: 2rem; line-height: 2rem">
              {{ startDate }} - {{ endDate }}
            </div>
            <div style="padding-left: 1rem; font-size: 1rem; color: #7a7a7a; height: 2rem; line-height: 2rem">
              <span>血糖达标范围</span>
              <span style="border: 1px solid #7a7a7a; padding: 0 0.5rem; font-size: 0.8rem">
                {{ scopeOfBloodSugar.low }}
              </span>
              -
              <span style="border: 1px solid #7a7a7a; padding: 0 0.5rem; font-size: 0.8rem">
                {{ scopeOfBloodSugar.high }}
              </span>
              <span>mmol/L</span>
            </div>
          </el-col>
          <!-- <el-col style="margin-top: 1rem" :span="6">
            <div
              @click="saveImage('html2canvas', '血糖报告')"
              style="
                width: 5.5rem;
                color: #7accff;
                height: 2rem;
                line-height: 2rem;
                padding-left: 0.5rem;
                border-radius: 16px;
                border: 2px solid #b8e3ff;
              ">
              <van-icon name="share-o" />
              <span style="font-size: 1rem">导出报告</span>
            </div>
          </el-col> -->
        </el-row>
      </div>

      <!-- 平均血糖 -->
      <div
        class="box-shadow"
        style="
          width: 94vw;
          margin: 0.5rem auto 0.8rem auto;
          height: 12rem;
          padding-top: 1rem;
          border-radius: 8px;
          background: #fff;
        "
      >
        <el-row style="height: 10rem">
          <el-col :span="12">
            <div
              style="
                padding-left: 1rem;
                text-align: left;
                font-size: 1rem;
                color: #7a7a7a;
                height: 1rem;
                line-height: 1rem;
              "
            >
              平均血糖
            </div>
            <div
              style="
                padding-left: 1rem;
                text-align: left;
                font-size: 3rem;
                color: #000;
                height: 4rem;
                font-weight: bold;
                line-height: 4rem;
              "
            >
              {{ averageSuger == 'NaN' ? '-' : averageSuger }}
              <span style="color: #7a7a7a; font-size: 0.8rem">mmol/L</span>
            </div>
            <div
              style="
                padding-left: 1rem;
                text-align: left;
                font-size: 1rem;
                color: #7a7a7a;
                height: 2rem;
                line-height: 2rem;
              "
            >
              预估糖化血红蛋白
            </div>
            <div
              style="
                padding-left: 1rem;
                text-align: left;
                font-size: 2rem;
                color: #000;
                height: 3rem;
                font-weight: bold;
                line-height: 3rem;
              "
            >
              {{ estimatedSaccharification == 'NaN' ? '-' : estimatedSaccharification }}
              <span style="color: #7a7a7a; font-size: 0.8rem">%</span>
            </div>
          </el-col>
          <el-col v-show="rateChart" :span="12">
            <div style="width: 3rem; height: 10rem; float: left; margin-left: 2rem">
              <div class="bloodRate1" style="background: rgb(255, 168, 0); width: 3rem"></div>
              <div class="bloodRate2" style="background: rgb(255, 212, 38); width: 3rem"></div>
              <div class="bloodRate3" style="background: rgb(101, 209, 114); width: 3rem"></div>
              <div class="bloodRate4" style="background: rgb(255, 113, 113); width: 3rem"></div>
              <div class="bloodRate5" style="background: rgb(238, 37, 37); width: 3rem"></div>
            </div>
            <div
              style="
                color: #333;
                width: 6rem;
                margin-left: 0.5rem;
                text-align: left;
                height: 10.5rem;
                float: left;
                font-size: 0.8rem;
              "
            >
              <div class="bloodRate1">{{ bloodRate[0] == 'NaN' ? '-' : bloodRate[0] }}% 非常高</div>
              <div class="bloodRate2" style="margin-left: -6.8rem">
                {{ bloodRate[1] == 'NaN' ? '-' : bloodRate[1] }}% 高
              </div>
              <div class="bloodRate3">{{ bloodRate[2] == 'NaN' ? '-' : bloodRate[2] }}% 正常</div>
              <div class="bloodRate4" style="margin-left: -6.8rem">
                {{ bloodRate[3] == 'NaN' ? '-' : bloodRate[3] }}% 低
              </div>
              <div class="bloodRate5">{{ bloodRate[4] == 'NaN' ? '-' : bloodRate[4] }}% 非常低</div>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 动态血糖图谱(AGP) -->
      <!-- <div
        class="box-shadow"
        style="
          width: 94vw;
          border-radius: 8px;
          padding-top: 1rem;
          background: #fff;
          margin: 0.5rem auto 0.8rem auto;
          height: 15.5rem;
          position: relative;
        "
      >
        <div
          style="
            padding-left: 1rem;
            height: 2rem;
            line-height: 1.2rem;
            text-align: left;
            font-size: 1.2rem;
            font-weight: bold;
            padding-bottom: 2rem;
          "
        >
          动态血糖图谱(AGP)
        </div>
        <div
          style="
            width: 250px;
            height: 24px;
            position: absolute;
            right: -20px;
            top: 30px;
          "
        >
          <el-row
            style="
              width: 70vw;
              height: 4vw;
              line-height: 12px;
              text-align: center;
            "
          >
            <el-col
              :offset="1"
              :span="3"
              style="height: 4px; margin-top: 4px; background: rgb(18, 28, 63)"
            ></el-col>
            <el-col
              :offset="2"
              :span="3"
              style="
                height: 4px;
                margin-top: 4px;
                background: rgb(122, 204, 255);
              "
            ></el-col>
            <el-col
              :offset="2"
              :span="3"
              style="
                height: 4px;
                margin-top: 4px;
                background: rgb(211, 238, 255);
              "
            ></el-col>
            <el-col
              :offset="2"
              :span="3"
              style="
                height: 6px;
                margin-top: 3px;
                border-bottom: 4px solid rgb(193, 231, 255);
              "
            ></el-col>
          </el-row>
          <el-row
            style="
              font-size: 0.6rem;
              width: 70vw;
              height: 4vw;
              line-height: 12px;
              text-align: center;
            "
          >
            <el-col :span="5">50%</el-col>
            <el-col :span="5">25%-75%</el-col>
            <el-col :span="5">10%-90%</el-col>
            <el-col :span="5">目标范围</el-col>
          </el-row>
        </div>
        <div style="width: 5%; height: 10rem; float: left; position: relative">
          <div style="color: #000; position: absolute; top: 33%">10</div>
          <div style="color: #000; position: absolute; top: 70%; right: 4px">
            3.9
          </div>
        </div>
        <div
          style="
            position: relative;
            width: 94%;
            float: left;
            height: 10rem;
            border: 1px solid #7a7a7a;
          "
        >
          <div
            v-for="(item, i) in lineList.y"
            :key="i + 'y'"
            class="dottedLiney"
            :style="{ top: item + '%' }"
          ></div>
          <div
            v-for="(item, i) in lineList.x"
            :key="i + 'x'"
            class="dottedLine"
            :style="{ left: item + '%' }"
          >
            <div style="position: absolute; top: 106%; left: -10px">
              {{ (i + 1) * 3 + ":00" }}
            </div>
          </div>
          <div
            id="echart"
            style="
              width: 111%;
              height: 150%;
              position: absolute;
              left: -24px;
              top: -34px;
            "
          ></div>
        </div>
      </div> -->

      <!-- 每周血糖 -->
      <weeklySugar />

      <!-- 统计参数 -->
      <div
        class="box-shadow"
        style="
          width: 94vw;
          padding-top: 1rem;
          height: 23rem;
          position: relative;
          margin: 0.5rem auto 0.8rem auto;
          background: #fff;
          border-radius: 1rem;
        "
      >
        <div
          style="
            padding-left: 1rem;
            height: 2rem;
            line-height: 2rem;
            text-align: left;
            font-size: 1.2rem;
            font-weight: bold;
          "
        >
          统计参数
        </div>
        <el-row>
          <el-col :span="7">
            <div v-show="pieChartData" id="pieChartData" style="width: 100%; height: 8rem; margin-top: 3rem"></div>
          </el-col>
          <el-col :span="17">
            <div style="width: 94%; margin-left: 3%; height: 12rem">
              <div style="width: 100%; height: 3rem">
                <div class="tlrRadious bacE8f6ff" style="width: 20%; height: 100%; float: left"></div>
                <div class="tlrRadious bacFf7171" style="width: 20%; height: 100%; float: left">
                  <div class="h15lh15">低血糖</div>
                  <div class="h15lh15">(&lt;3.9)</div>
                </div>
                <div class="tlrRadious bac65d172" style="width: 20%; height: 100%; float: left">
                  <div class="h3lh3">范围内</div>
                </div>
                <div class="tlrRadious bacFfd426" style="width: 20%; height: 100%; float: left">
                  <div class="h15lh15">高血糖</div>
                  <div class="h15lh15">(>10.0)</div>
                </div>
                <div class="tlrRadious bacE8f6ff" style="width: 20%; height: 100%; float: left">
                  <div class="h3lh3">概览</div>
                </div>
              </div>
              <div style="width: 100%; height: 9rem">
                <div v-for="(item, i) in statisticalParameters" :key="i" style="width: 100%">
                  <div
                    :class="{
                      blrRadious: i == '3',
                      bacE8f6ff: true,
                      borderTopE8f6: true,
                      w3h3fl: i == '3',
                      w2h2fl: i != '3',
                    }"
                  >
                    {{ item[0] == 'NaN' ? '-' : item[0] }}
                  </div>
                  <div
                    :class="{
                      blrRadious: i == '3',
                      bacFf7171: true,
                      borderTop: true,
                      w3h3fl: i == '3',
                      w2h2fl: i != '3',
                    }"
                  >
                    {{ item[1] == 'NaN' ? '-' : item[1] }}
                  </div>
                  <div
                    :class="{
                      blrRadious: i == '3',
                      bac65d172: true,
                      borderTop: true,
                      w3h3fl: i == '3',
                      w2h2fl: i != '3',
                    }"
                  >
                    {{ item[2] == 'NaN' ? '-' : item[2] }}
                  </div>
                  <div
                    :class="{
                      blrRadious: i == '3',
                      bacFfd426: true,
                      borderTop: true,
                      w3h3fl: i == '3',
                      w2h2fl: i != '3',
                    }"
                  >
                    {{ item[3] == 'NaN' ? '-' : item[3] }}
                  </div>
                  <div
                    :class="{
                      blrRadious: i == '3',
                      bacE8f6ff: true,
                      borderTop: true,
                      w3h3fl: i == '3',
                      w2h2fl: i != '3',
                    }"
                    style=""
                  >
                    {{ item[4] == 'NaN' ? '-' : item[4] }}
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div
          style="width: 94%; margin-left: 3%; color: #000; height: 7rem; border-radius: 12px; margin-top: 0.5rem"
          class="bacE8f6ff"
        >
          <el-row>
            <el-col :span="8" style="height: 6rem">
              <div class="w100h4br">
                <div style="height: 2rem; line-height: 2rem; font-weight: bold">SD标准偏差</div>
                <div style="height: 3rem; line-height: 3rem; font-size: 2rem; font-weight: bold">
                  {{ standardError == 'NaN' ? '-' : standardError }}
                  <span style="font-size: 0.8rem">mmol/L</span>
                </div>
                <div style="color: rgb(175, 189, 189)">&lt;2mmol/L</div>
              </div>
            </el-col>
            <el-col :span="8" style="height: 6rem">
              <div class="w100h4br">
                <div style="height: 2rem; line-height: 2rem; font-weight: bold">CV变异系数</div>
                <div style="height: 3rem; line-height: 3rem; font-size: 2rem; font-weight: bold">
                  {{ CV == 'NaN' ? '-' : CV }}
                  <span style="font-size: 0.8rem">%</span>
                </div>
                <div style="color: rgb(175, 189, 189)">>33%</div>
              </div>
            </el-col>

            <el-col :span="8" style="height: 6rem">
              <div style="width: 100%; margin-top: 0.5rem; height: 5rem">
                <div style="height: 2rem; line-height: 2rem; font-weight: bold">PGS血糖状态</div>
                <div style="height: 3rem; line-height: 3rem; font-size: 2rem; font-weight: bold">
                  {{ PGS == 'NaN' ? '-' : PGS }}
                </div>
                <div style="color: rgb(175, 189, 189)">&lt;20</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div style="width: 100%; height: 2rem"></div>
    </div>

    <div v-if="SugerData.length == 0" style="margin-top: 50%">
      <img src="../../static/pic/nodata.png" alt="" />
      <div style="font-size: 1.5rem; font-weight: 700; margin-top: 1rem; color: #10abff">暂无数据</div>
    </div>
  </div>
</template>
<script>
  import Report from '@/pages/reportCenter/report.vue'
  import html2canvas from 'html2canvas'
  import todaySugar from './component/todaySugar.vue'
  import weeklySugar from './component/weeklySugar.vue'
  import { getBGbyToken } from '../../api/userServices'
  import {
    getaverage,
    getprotein,
    getstandard,
    getRate,
    getCV,
    getPGS,
    formatData,
    getAllNum,
    getAllMid,
    getLow,
    getNormal,
    getHigh,
    getSevenData,
    getLowAvg,
    getNormalAvg,
    getHighAvg,
    getLowMid,
    getNormalMid,
    getHighMid,
    getLowStandard,
    getNormalStandard,
    getHighStandard,
    get24HourData,
    getLineChartData,
    getMaxRange,
  } from '../sugerData/getSugerData'
  import { Dialog } from 'vant'
  export default {
    name: 'ReportCenter',
    components: { todaySugar, weeklySugar, Report },
    data() {
      return {
        loading: false,
        // 每日血糖概览
        dayAverageSuger: '-',
        // 最大血糖波动幅度
        dayMaxRange: '-',
        // 高血糖数
        dayMaxSugar: '-',
        // 低血糖数
        dayMinSugar: '-',
        selectedDate: '',
        nowDate: '',
        // 自定义时间最大最小值
        minDate: new Date(2010, 0, 1),
        maxDate: new Date(),
        showStart: false,
        daysArr: [],
        weeksArr: [],
        bg14: [],
        // 血糖达标范围
        scopeOfBloodSugar: {
          high: 10,
          low: 3.9,
          extremelyHigh: 12,
          extremelyLow: 2,
        },
        test: '',
        SugerData: '',
        rateChart: false,
        startDate: '',
        endDate: '',
        averageSuger: '',
        estimatedSaccharification: '',
        standardError: '',
        bloodRate: [12, 24, 24, 12, 28],
        CV: '',
        PGS: '',
        lineList: {
          x: [12.5, 25, 37.5, 50, 62.5, 75, 87.5],
          y: [74, 33.3],
        },
        pieChartData: [
          { value: 0, itemStyle: { color: '#ff1010' } },
          { value: 0, itemStyle: { color: '#65d172' } },
          { value: 0, itemStyle: { color: '#ffd426' } },
        ],
        statisticalParameters: [
          ['已读取', '10%', '80%', '10%', '优秀'],
          ['平均值', '10%', '80%', '10%', '优秀'],
          ['中位数', '10%', '80%', '10%', '优秀'],
          ['标准差', '10%', '80%', '10%', '优秀'],
        ],
        classList: ['bloodRate1', 'bloodRate2', 'bloodRate3', 'bloodRate4', 'bloodRate5'],
        // 每周对比图数据
        thisWeeks: [],
        lastWeeks: [],
        // 判断是否是患者报告页
        isPatient: '',
        structId: '',
      }
    },
    watch: {
      selectedDate: {
        handler: function (newVal, oldVal) {
          this.getDaySugar(newVal)
        },
      },
    },

    mounted() {
      this.initialization()

      // 分享报告
      window.saveImageToBlob = this.saveImageToBlob
    },
    created() {
      this.isPatient = this.$route.query.id ? true : false
      this.structId = this.$route.query.struct_id ? this.$route.query.struct_id : ''
      this.nowDate = this.$moment().format('YYYY-MM-DD')
      this.selectedDate = this.$moment().format('YYYY-MM-DD')
      setTimeout(() => {
        this.setBloodRate()
        this.drawPieChart()
        this.getBG14()
      }, 300)
    },
    methods: {
      // 返回我的患者列表
      toMyPatientList() {
        this.$router.push({
          path: '/MineView/PersonnelManagement',
          query: {
            structId: this.structId,
          },
        })
      },
      // 每日血糖概览
      getDaySugar(date) {
        const params = {
          end: date + ' 23:59:59',
          start: date + ' 00:00:00',
        }
        getBGbyToken(params).then(res => {
          if (res.data.data && res.data.data.length) {
            // 平均葡萄糖水平
            this.dayAverageSuger = getaverage(formatData(res))
            this.dayMaxRange = getMaxRange(formatData(res))
            this.dayMaxSugar = getHigh(
              formatData(res),
              this.$route.query.targetHigh || localStorage.getItem('targetHigh')
            )
            this.dayMinSugar = getLow(formatData(res), this.$route.query.targetLow || localStorage.getItem('targetLow'))
          } else {
            this.dayAverageSuger = 0
            this.dayMaxRange = 0
            this.dayMaxSugar = 0
            this.dayMinSugar = 0
          }
        })
      },
      // 前一天
      onClickLeft() {
        this.selectedDate = this.$moment(this.selectedDate).subtract(1, 'days').format('YYYY-MM-DD')
      },
      // 后一天
      onClickRight() {
        this.selectedDate = this.$moment(this.selectedDate).add(1, 'days').format('YYYY-MM-DD')
      },
      // 打开自定义日期弹框
      showDialog() {
        this.showStart = true
      },
      // 关闭自定义日期弹框
      cancelStart() {
        this.showStart = false
      },
      // 保存自定义弹框
      confirmStart(date) {
        this.selectedDate = this.$moment(date).format('YYYY-MM-DD')
        this.showStart = false
      },
      //图片格式转换方法
      dataURLToBlob(dataurl) {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], { type: mime })
      },
      // 截屏方法
      saveImage(divText, imgText) {
        const canvasID = this.$refs[divText]
        html2canvas(canvasID).then(canvas => {
          const img = canvas.toDataURL('image/jpeg', 0.8).slice(23)
          const message = {
            message: '图片分享',
            body: {
              blob: img,
              type: 'report',
            },
          }
          const androidMessage = JSON.stringify(message)

          const phoneType = localStorage.getItem('phoneType')
          if (phoneType == 'Android') {
            window.android.saveImageToBlob(androidMessage)
          } else {
            window.webkit.messageHandlers.saveImageToBlob.postMessage(message)
          }
        })
      },
      getBG14() {
        this.loading = true
        const endDate = this.$moment().format('YYYY-MM-DD 23:59:59')
        const startDate = this.$moment().subtract('14', 'days').format('YYYY-MM-DD 00:00:00')
        const params = {
          end: endDate,
          start: startDate,
        }
        this.startDate = startDate
        this.endDate = endDate
        getBGbyToken(params).then(res => {
          this.SugerData = formatData(res)
          this.bg14 = res.data.data.map(v => {
            return { data: v.v, date: v.create_time }
          })
          this.handleBG()

          const percentileData = get24HourData(this.SugerData)
          console.log(percentileData, 'percentileData')
          const perenctDiff = getLineChartData(percentileData)

          this.$nextTick(() => {
            // this.drawChart(perenctDiff);

            // 统计参数饼图数据
            this.pieChartData[0].value = getLow(this.SugerData)
            this.pieChartData[1].value = getNormal(this.SugerData)
            this.pieChartData[2].value = getHigh(this.SugerData)

            this.drawPieChart()
          })
          // 平均血糖柱状图数据
          this.bloodRate[0] = getRate(this.SugerData, 13.9, 100)
          this.bloodRate[1] = getRate(this.SugerData, 10, 13.9)
          this.bloodRate[2] = getRate(this.SugerData, 3.8, 10)
          this.bloodRate[3] = getRate(this.SugerData, 3, 3.8)
          this.bloodRate[4] = getRate(this.SugerData, 0, 3)
          this.setBloodRate()

          this.averageSuger = this.statisticalParameters[1][4] = getaverage(this.SugerData)
          this.estimatedSaccharification = getprotein(this.SugerData)
          this.standardError = this.statisticalParameters[3][4] = getstandard(this.SugerData, this.averageSuger)

          this.CV = getCV(this.averageSuger, this.standardError)
          this.PGS = getPGS(this.SugerData)

          this.statisticalParameters[0][0] = '已读取'
          this.statisticalParameters[0][1] = getLow(this.SugerData)
          this.statisticalParameters[0][2] = getNormal(this.SugerData)
          this.statisticalParameters[0][3] = getHigh(this.SugerData)
          this.statisticalParameters[0][4] = getAllNum(this.SugerData)
          this.statisticalParameters[2][4] = getAllMid(this.SugerData)
          this.statisticalParameters[1][0] = '平均值'
          this.statisticalParameters[1][1] = getLowAvg(this.SugerData)
          this.statisticalParameters[1][2] = getNormalAvg(this.SugerData)
          this.statisticalParameters[1][3] = getHighAvg(this.SugerData)
          this.statisticalParameters[2][0] = '中位数'
          this.statisticalParameters[2][1] = getLowMid(this.SugerData) + ''
          this.statisticalParameters[2][2] = getNormalMid(this.SugerData)
          this.statisticalParameters[2][3] = getHighMid(this.SugerData)
          this.statisticalParameters[3][0] = '标准差'
          this.statisticalParameters[3][1] = getLowStandard(this.SugerData)
          this.statisticalParameters[3][2] = getNormalStandard(this.SugerData)
          this.statisticalParameters[3][3] = getHighStandard(this.SugerData)
          this.test = getSevenData(res)
        })
      },
      handleBG() {
        var T = 'YYYY-MM-DD HH:mm:ss'
        for (var i = 0; i < 14; i++) {
          var s = this.$moment().add(0, 'days').startOf('day')
          var e = this.$moment().add(1, 'days').startOf('day')
          var s1 = s.subtract(i, 'days')
          var e1 = e.subtract(i, 'days')
          this.daysArr.push({
            start: s1.format(T),
            end: e1.format(T),
            arr: [],
            title: s1.format('DD'),
          })
        }
        for (var j = 0; j < 2; j++) {
          var wstart = this.$moment().subtract(7 * (j + 1), 'days')
          var wend = this.$moment().subtract(7 * j, 'days')
          this.weeksArr.push({
            start: wstart.format(T),
            end: wend.format(T),
            arr: [],
          })
        }
        this.daysArr.reverse()
        this.checkBG()
      },
      checkBG() {
        var that = this

        this.bg14.forEach(v => {
          var t = v.date
          that.daysArr.forEach((v1, i1, arr1) => {
            if (v1.start <= t && t < v1.end) {
              arr1[i1].arr.push(v)
            }
          })
          that.weeksArr.forEach((v2, i2, arr2) => {
            if (v2.start <= t && t < v2.end) {
              arr2[i2].arr.push(v.data)
            }
          })
        })

        // 每周对比的数据及渲染
        that.thisWeeks = that.weeksArr[1].arr
        that.lastWeeks = that.weeksArr[0].arr

        this.loading = false
      },
      // 自定义时间转换
      formatDate(date) {
        return `${date.getYear() + 1900}-${date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${
          date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        }`
      },
      // 初始化页面初始数据
      initialization() {
        const userInfo = this.$route.query
        this.scopeOfBloodSugar.high = userInfo.target_high ? userInfo.target_high : this.scopeOfBloodSugar.high
        this.scopeOfBloodSugar.low = userInfo.target_low ? userInfo.target_low : this.scopeOfBloodSugar.low
      },

      setBloodRate() {
        for (let index = 0; index < this.classList.length; index++) {
          const element = this.classList[index]
          document.getElementsByClassName(element)[0].style.height = this.bloodRate[index] / 10 + 'rem'
          document.getElementsByClassName(element)[1].style.height = this.bloodRate[index] / 10 + 'rem'
          document.getElementsByClassName(element)[1].style['line-height'] = this.bloodRate[index] / 10 + 'rem'
        }
        this.rateChart = true
      },
      drawChart(perenctDiff) {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        const myChart = this.$echarts.init(document.getElementById('echart'))
        // 指定图表的配置项和数据
        const option = {
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              splitLine: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              show: false,
              type: 'category',
              boundaryGap: false,
              data: [
                '0:00',
                '1:00',
                '2:00',
                '3:00',
                '4:00',
                '5:00',
                '6:00',
                '7:00',
                '8:00',
                '9:00',
                '10:00',
                '11:00',
                '12:00',
                '13:00',
                '14:00',
                '15:00',
                '16:00',
                '17:00',
                '18:00',
                '19:00',
                '20:00',
                '21:00',
                '22:00',
                '23:00',
              ],
            },
          ],
          yAxis: [
            {
              splitNumber: 3,
              splitLine: {
                show: false,
              },
              type: 'value',
              show: false,
              axisLine: {
                show: false,
              },
            },
          ],
          splitLine: { show: false },
          series: [
            // {
            //   name: "_10",
            //   type: "line",
            //   showSymbol: false,
            //   smooth: true,
            //   stack: "Total",
            //   areaStyle: {
            //     color: "#fff",
            //   },
            //   lineStyle: {
            //     width: 0,
            //   },
            //   emphasis: {
            //     focus: "series",
            //   },
            //   data: perenctDiff._10_0,
            // },
            {
              name: '_25',
              type: 'line',
              showSymbol: false,
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0,
              },
              areaStyle: {
                color: 'blue',
              },
              emphasis: {
                focus: 'series',
              },
              data: perenctDiff._25_10,
            },
            {
              name: '_50_25',
              type: 'line',
              showSymbol: false,
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0,
              },
              areaStyle: {
                color: 'red',
              },
              emphasis: {
                focus: 'series',
              },
              data: perenctDiff._50_25,
            },
            {
              name: '_50',
              type: 'line',
              showSymbol: false,
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 3,
              },
              // areaStyle: {
              //   color: "#000",
              // },
              emphasis: {
                focus: 'series',
              },
              data: perenctDiff._50,
              // data: [
              //   0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1,
              //   0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1,
              // ],
            },
            {
              name: '_75',
              type: 'line',
              showSymbol: false,
              smooth: true,
              lineStyle: {
                width: 0,
              },
              stack: 'Total',
              areaStyle: {
                color: 'rgb(122,204,255)',
              },
              emphasis: {
                focus: 'series',
              },
              data: perenctDiff._75_50,
            },
            {
              name: '_75',
              type: 'line',
              showSymbol: false,
              smooth: true,
              lineStyle: {
                width: 0,
              },
              stack: 'Total',
              areaStyle: {
                color: 'blue',
              },
              emphasis: {
                focus: 'series',
              },
              data: perenctDiff._90_75,
            },
          ],
        }
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option)
      },
      drawPieChart() {
        // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
        const myChart = this.$echarts.init(document.getElementById('pieChartData'))
        const options = {
          legend: {
            orient: 'vertical',
            left: 'left',
            data: [],
          },
          series: [
            {
              hoverAnimation: false, //鼠标悬浮是否有区域弹出动画，false:无 true:有
              avoidLabelOverlap: false,
              type: 'pie',
              radius: '100%',
              label: {
                normal: {
                  position: 'inner',
                  show: true,
                },
              },
              data: this.pieChartData,
            },
          ],
        }
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(options)
      },
    },
  }
</script>
<style scoped>
  #reportCenter {
    background: #f8f8f8;
    padding-bottom: 5rem;
    overflow: hidden;
  }

  .tip {
    height: 3rem;
    line-height: 3rem;
    text-align: left;
    padding-left: 2rem;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    background: #fff;
  }
  .tip-top {
    height: 4rem;
    line-height: 4rem;
    text-align: left;
    padding-left: 2rem;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    background: #fff;
  }

  .navigation {
    background: #d3eeff;
    width: 100vw;
    height: 4rem;
    line-height: 4rem;
    overflow: hidden;
    color: #7accff;
  }

  .tlrRadious {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .blrRadious {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .bacE8f6ff {
    background: #e8f6ff;
  }

  .w100h4br {
    width: 100%;
    height: 6rem;
    margin-top: 0.5rem;
    border-right: 1px #9a9a9a solid;
  }

  .bacFf7171 {
    background: #ff7171;
  }

  .bac65d172 {
    background: #65d172;
  }

  .bacFfd426 {
    background: #ffd426;
  }

  .colorTitle {
    color: #a2dafc;
  }

  .borderTop {
    border-top: 2px solid #fff;
  }

  .borderTopE8f6 {
    border-top: 2px solid #e8f6ff;
  }

  .w2h2fl {
    width: 20%;
    height: 100%;
    float: left;
    height: 2rem;
    line-height: 2rem;
  }

  .w3h3fl {
    width: 20%;
    height: 100%;
    float: left;
    height: 3rem;
    line-height: 3rem;
  }

  #echarts canvas {
    position: absolute;
    left: -35px;
    top: 9px;
    width: 340px;
    height: 180px;
  }

  .h15lh15 {
    height: 1.5rem;
    line-height: 1.5rem;
  }

  .h3lh3 {
    height: 3rem;
    line-height: 3rem;
  }

  .colorSelected {
    color: #7accff;
  }

  .dottedLine {
    width: 1px;
    height: 94%;
    border-right: 1px dotted #ccc;
    position: absolute;
    z-index: 9;
  }

  .dottedLiney {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #7accff;
    position: relative;
    z-index: 9;
  }

  .unSelectedDay {
    color: #7ecdff;
    background: #e8f6ff;
    width: 80px;
    line-height: 26px;
    font-size: 1rem;
    height: 26px;
    border-radius: 16px;
    margin: auto;
  }

  /* 盒子阴影样式 */
  .box-shadow {
    box-shadow: 2px 2px 2px 0px rgba(6, 14, 33, 0.16);
    padding: 0.5rem;
  }
  .none {
    display: none;
  }
  .show {
    display: block;
  }
  [class^='bloodRate'] {
    min-height: 3px;
    margin-top: 1px;
    border-radius: 2px;
  }
</style>
