<template>
  <div class="common-box">
    <!-- 标题 -->
    <div class="title">多日血糖曲线</div>
    <!-- 散点图 -->
    <div id="weeklySugar" style="width: 100%; height: 22rem"></div>
  </div>
</template>
<script>
  import { Line } from '@antv/g2plot'
  import { getBGbyToken, getSugerbyId } from '@/api/userServices'
  export default {
    props: {
      userId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        scatterPlot: null,
        chartData: [],
        // 时间段选择
        time: 3,
      }
    },
    created() {
      setTimeout(() => {
        this.getChart()
      }, 300)

      if (this.$route.query.id) {
        this.getDateById()
      } else {
        this.getDateArray()
      }
    },
    mounted() {},
    methods: {
      getChart() {
        let data = this.chartData
        console.log(data, 'data')
        this.scatterPlot = new Line('weeklySugar', {
          appendPadding: [30, 5, 10, 5],
          data,
          xField: 'create_time',
          yField: 'v',
          seriesField: 'tooltip_time',
          xAxis: {
            type: 'time',
            max: this.maxTime,
            min: this.minTime,
            mask: 'HH:mm', // 显示格式为小时:分钟
            tickMethod: scale => {
              const { min, max, tickCount } = scale
              // const avg = (max - min) / tickCount;
              const ticks = []
              for (let i = min; i <= max; i += 60 * 60 * 1000) {
                ticks.push(i)
              }
              return ticks
            },
            // line: {
            //   visible: true
            // }
          },
          yAxis: {
            nice: true,
            min: 0,
            max: 25,
            grid: {
              line: {
                style: {
                  lineWidth: 2,
                  lineDash: [4, 5],
                },
              },
            },
          },
          legend: {
            position: 'bottom',
            flipPage: false,
          },
          smooth: true,
          // @TODO 后续会换一种动画方式
          animation: {
            appear: {
              animation: 'path-in',
              duration: 2000,
            },
          },
        })
        this.scatterPlot.render()
      },

      getDateArray() {
        this.chartData = []
        const params = {
          end: this.$moment().format('YYYY-MM-DD 23:59:59'),
          start: this.$moment().subtract(6, 'day').format('YYYY-MM-DD 00:00:00'),
        }
        getBGbyToken(params).then(res => {
          const today = this.$moment().format('YYYY-MM-DD')
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach(item => {
              this.chartData.push({
                create_time: today + ' ' + item.create_time.substring(11, 16),
                v: item.v,
                tooltip_time: item.create_time.substring(5, 10),
              })
            })
          }
        })
      },

      // getDateById() {
      //   this.chartData = []
      //   const params = {
      //     id: this.$route.query.id,
      //     end: this.$moment().format('YYYY-MM-DD'),
      //     start: this.$moment().subtract(6, 'day').format('YYYY-MM-DD'),
      //   }
      //   getSugerbyId(params).then(res => {
      //     const today = this.$moment().format('YYYY-MM-DD')
      //     if (res.data.data && res.data.data.length) {
      //       res.data.data.forEach(item => {
      //         this.chartData.push({
      //           create_time: today + ' ' + item.create_time.substring(11, 16),
      //           v: item.v,
      //           tooltip_time: item.create_time.substring(5, 10),
      //         })
      //       })
      //     }
      //   })
      // },
    },
    computed: {
      maxTime() {
        const nextTime = this.$moment().add(1, 'days').format('YYYY-MM-DD 00:00:00')
        return new Date(nextTime).getTime()
      },
      minTime() {
        const min = this.$moment().format('YYYY-MM-DD 00:00:00')
        return new Date(min).getTime()
      },
    },
    watch: {
      chartData: {
        handler: function (newVal, oldVal) {
          if (this.scatterPlot) {
            this.$nextTick(() => {
              this.scatterPlot.destroy()
              this.getChart()
            })
          }
        },
      },
    },
  }
</script>
<style scoped>
  .common-box {
    width: 96%;
    margin: 0.6rem;
    border-radius: 1.2rem;
    background: rgb(255, 255, 255);
    box-shadow: 2px 2px 2px 0px rgba(6, 14, 33, 0.16);
    position: relative;
  }
  .title {
    margin-bottom: -22px;
    height: 3.4rem;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: start;
    line-height: 3.4rem;
    /* border-bottom: 1px solid #ccc; */
    padding-left: 1rem;
  }
  .van-radio-group--horizontal {
    justify-content: center;
  }
  .chartCan {
    width: 100%;
    background: #d3eeff;
    position: absolute;
    border-top: 2px dotted #c1b8b8;
    border-bottom: 2px dotted #c1b8b8;
    height: 4.5rem;
    bottom: 9.4rem;
  }
  .bottom-num {
    position: absolute;
    right: 5px;
    bottom: -16px;
    color: red;
  }
  .top-num {
    position: absolute;
    right: 5px;
    top: -16px;
    color: orange;
  }
  .unit {
    position: absolute;
    left: 5px;
    margin-top: 5px;
  }
  .van-radio__label {
    color: #7accff;
  }
</style>
